import React, { useContext, useEffect, useState } from 'react'
import ScreenWrapper from '../components/ScreenWrapper'
import Header from '../components/Header'
import { AuthContext } from '../context/authContext';
import { addProject, getMasterProject, getZeList } from '../request/request';
import { formatCurrency } from '../utils/helper';
import CheckBox from '../components/CheckBox';
import Checkbox from '../components/CheckBox';
import Switch from '../components/Switch';
import { useNavigate } from 'react-router-dom';
import { File, FileText } from 'react-feather';
import BottomBar from '../components/BottomBar';

const renderDocumentPreview = (documents) => {
    if (documents.length === 0) return null;

    console.log('Documents State:', documents); // Log documents state for debugging

    return documents.map((doc, index) => {
        const docType = doc.type || 'unknown'; // Handle undefined type
        const docName = doc.name || 'Untitled'; // Handle undefined name
        const docUrl = URL.createObjectURL(doc); // Create an object URL for the file

        return (
            <div key={index} style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '10px',
            }}>
                {docType === 'application/pdf' ? (
                    <FileText size={50} color="red" />
                ) : docType.startsWith('image/') ? (
                    <img src={docUrl} alt={docName} style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '5px',
                    }} />
                ) : (
                    <File size={50} color="gray" />
                )}
                <span style={{
                    marginLeft: '10px',
                    fontSize: '16px',
                    flex: 1,
                    color: 'black',
                }}>{docName}</span>
            </div>
        );
    });
};

function AddProjectScreen() {

    const [selectedMasterProject, setSelectedMasterProject] = useState(null);
    const [projectName, setProjectName] = useState('');

    const [entrustingDepartment, setEntrustingDepartment] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [dateCompletion, setDateCompletion] = useState(new Date());
    const [mtrDocComment, setMtrDocComment] = useState('');
    const [mtrDocFlag, setMtrDocFlag] = useState(true);
    const [open, setOpen] = useState(false)

    const [totalAmount, setTotalAmount] = useState(0);
    const [estimateCost, setEstimateCost] = useState(0);
    const [masterProjects, setMasterProjects] = useState([]);
    const [zeList, setZeList] = useState([]);
    const [selectedZe, setSelectedZe] = useState(null);
    const [raFiles, setRaFiles] = useState([]);
    const [geoTaggingFiles, setGeoTaggingFiles] = useState([]);
    const [testingReports, setTestingReports] = useState([]);
    const [percentageOfWorkDone, setPercentageOfWorkDone] = useState('');
    const [valueOfWorkDone, setValueOfWorkDone] = useState('');
    const [valueOfStockAtDate, setValueOfStockAtDate] = useState('');
    const [selfDeclaration1, setSelfDeclaration1] = useState(false);
    const [selfDeclaration2, setSelfDeclaration2] = useState(false);
    const [selfDeclaration3, setSelfDeclaration3] = useState(false);
    const [selfDeclaration4, setSelfDeclaration4] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const selectValueWorkdone = (e) => {
        const newValueOfWorkDone = e.target.value;
        setValueOfWorkDone(newValueOfWorkDone);
        setTotalAmount(Number(valueOfStockAtDate) + Number(newValueOfWorkDone));
    };

    const selectValueStockdone = (e) => {
        const newValueOfStockAtDate = e.target.value;
        setValueOfStockAtDate(newValueOfStockAtDate);
        setTotalAmount(Number(newValueOfStockAtDate) + Number(valueOfWorkDone));
    };

    const [state] = useContext(AuthContext);
    const currentUser = state.user;


    useEffect(() => {
        const fetchProjectList = async () => {
            try {
                const responseMasterProject = await getMasterProject();
                (responseMasterProject.res_code == 200) ? setMasterProjects(responseMasterProject.data) : alert(responseMasterProject.message);
            } catch (error) {
                console.error('Error fetching contractor list:', error);
            }
        };

        fetchProjectList();
    }, []);

    useEffect(() => {
        const fetchProjectList = async () => {
            try {
                const responseMasterProject = await getZeList();
                (responseMasterProject.res_code == 200) ? setZeList(responseMasterProject.data) : alert(responseMasterProject.message);
            } catch (error) {
                console.error('Error fetching contractor list:', error);
            }
        };

        fetchProjectList();
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        if (!currentUser) {
            alert('User not authenticated');
            navigate('/login');
        }
        if (!selectedZe) {
            alert('Zonal Engineer required');
            setLoading(false);
            return;
        }
        if (!selectedMasterProject) {
            alert('project name required');
            setLoading(false);
            return;
        }
        if (!entrustingDepartment) {
            alert('Entrusting department required');
            setLoading(false);
            return;
        }
        if (!projectName) {
            alert('Nature or work required');
            setLoading(false);
            return;
        }
        if (!projectDescription) {
            alert('Description or work required');
            setLoading(false);
            return;
        }
        if (!dateCompletion) {
            alert('date of completion required');
            setLoading(false);
            return;
        }
        if (!percentageOfWorkDone) {
            alert('Percent of work done required');
            setLoading(false);
            return;
        }
        if (Number(percentageOfWorkDone) > 100) {
            alert('percent should be less than 100');
            setLoading(false);
            return;

        }
        if (!estimateCost) {
            alert('Estimated cost required');
            setLoading(false);
            return;
        }
        if (!valueOfWorkDone) {
            alert('Value of work done required');
            setLoading(false);
            return;
        }
        if (!valueOfStockAtDate) {
            alert('Value of sotck at site required');
            setLoading(false);
            return;
        }
        if (!totalAmount) {
            alert('Total amount required');
            setLoading(false);
            return;
        }
        if (raFiles.length == 0) {
            console.log(testingReports);
            alert('RA Bill required');
            setLoading(false);
            return;
        }
        if (geoTaggingFiles.length < 5) {
            console.log(testingReports);
            alert('Min 5 Geo-Photos required');
            setLoading(false);
            return;
        }
        if (!selfDeclaration1 || !selfDeclaration2 || !selfDeclaration3 || !selfDeclaration4) {
            alert('All Self Declaration required');
            setLoading(false);
            return;
        }
        if (mtrDocFlag == true) {
            if (testingReports.length == 0) {
                alert('Material test report required');
                setLoading(false);
                return;
            }
        } else {
            if (!mtrDocComment) {
                alert('Material Report Comment required');
                setLoading(false);
                return;
            }
        }

        try {
            const addTaskResponse = await addProject(
                projectName,
                currentUser.user_id,
                currentUser.role_id,
                selectedMasterProject,
                totalAmount,
                percentageOfWorkDone,
                valueOfWorkDone,
                valueOfStockAtDate,
                selfDeclaration1,
                selfDeclaration2,
                selfDeclaration3,
                selfDeclaration4,
                raFiles,
                geoTaggingFiles,
                testingReports, selectedZe, estimateCost, entrustingDepartment, projectDescription, dateCompletion, mtrDocComment, mtrDocFlag
            );
            // console.log(addTaskResponse);
            
            (addTaskResponse.res_code == 200) ? navigate('/home') : alert(addTaskResponse.message);
        } catch (error) {
            console.log(error);
            
            alert('error while adding payment request.');
        }
        setLoading(false);
    }

    const handleFilePick = (event, setFiles) => {
        const files = Array.from(event.target.files);
        setFiles(files);
    };

    return (
        <ScreenWrapper>
            <div className='flex-1 flex flex-col'>
                <Header />
                <div className="flex align-center justify-center mx-4 mt-4 mb-16">
                    <div className="bg-white p-4 rounded-2xl shadow-sm space-y-4 mb-4" style={{ maxWidth: 400 }}>
                        <div className="flex-row">
                            <h6 className="text-lg text-center font-bold text-primary">New Payment Request       </h6>
                        </div>

                        <div className='space-y-1'>
                            <h6 className="text-lg font-bold">1. Select Zonal Engineer</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <select
                                        value={selectedZe}
                                        onChange={(event) => {
                                            setSelectedZe(event.target.value);
                                        }}
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            padding: '0 1rem',
                                            backgroundColor: '#ffffff',
                                        }}
                                    >
                                        <option value="" disabled selected>Select ZE</option>
                                        {zeList.map(z => (
                                            <option key={z.user_id} value={z.user_id}>
                                                {z.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">2. Project Name</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <select
                                        value={selectedMasterProject}
                                        onChange={(event) => {
                                            setSelectedMasterProject(event.target.value);
                                        }}
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            padding: '0 1rem',
                                            backgroundColor: '#ffffff',
                                        }}
                                    >
                                        <option value="" disabled selected>Select Project</option>
                                        {masterProjects.map(mp => (
                                            <option key={mp.master_pid} value={mp.master_pid}>
                                                {mp.master_project_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">3. Entrusting Department</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="text"
                                        value={entrustingDepartment}
                                        onChange={(e) => setEntrustingDepartment(e.target.value)}
                                        placeholder="Enter entrusting department"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">4. Stage of work</h6>
                            <h6 className="text-lg font-bold">A) Nature of work</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="text"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        placeholder="Enter nature of project"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">B) Description of work</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="text"
                                        value={projectDescription}
                                        onChange={(e) => setProjectDescription(e.target.value)}
                                        placeholder="Enter project desc"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">5. Completion Date</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="date"
                                        value={dateCompletion}
                                        onChange={(e) => setDateCompletion(e.target.value)}
                                        placeholder="Enter project name"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">6. A) Percentage of Work Done</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="number"
                                        value={percentageOfWorkDone}
                                        onChange={(e) => setPercentageOfWorkDone(e.target.value)}
                                        placeholder="% of work done"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">6. B) Estimated Cost</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="number"
                                        value={estimateCost}
                                        onChange={(e) => setEstimateCost(e.target.value)}
                                        placeholder="₹"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">7. (A) Value of Work Done</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="number"
                                        value={valueOfWorkDone}
                                        onChange={selectValueWorkdone}
                                        placeholder="₹"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">7. (B) Value of Stock at the site</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="number"
                                        value={valueOfStockAtDate}
                                        onChange={selectValueStockdone}
                                        placeholder="₹"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">8. Amount Total</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="number"
                                        value={totalAmount}
                                        placeholder="₹"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                        disabled
                                    />
                                </div>
                            </div>
                            <h6 className="text-lg font-bold">9. RA (running) Bill</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="relative flex items-center px-3 py-1 rounded-full border border-gray-300 bg-white">
                                    <input
                                        type="file"
                                        id="file-input"
                                        onChange={(e) => handleFilePick(e, setRaFiles)}
                                        className="absolute inset-0 opacity-0 cursor-pointer"
                                        multiple
                                    />
                                    <label
                                        htmlFor="file-input"
                                        className="flex items-center justify-center w-full h-10 px-4  text-black cursor-pointer"
                                    >
                                        {/* fileName || */}
                                        {'Upload Document'}
                                    </label>
                                </div>
                            </div>
                            {renderDocumentPreview(raFiles)}
                            <h6 className="text-lg font-bold">10. Geo-Tagged Picture (min 5)</h6>
                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="relative flex items-center px-3 py-1 rounded-full border border-gray-300 bg-white">
                                    <input
                                        type="file"
                                        id="file-input"
                                        onChange={(e) => handleFilePick(e, setGeoTaggingFiles)}
                                        className="absolute inset-0 opacity-0 cursor-pointer"
                                        multiple
                                    />
                                    <label
                                        htmlFor="file-input"
                                        className="flex items-center justify-center w-full h-10 px-4  text-black cursor-pointer"
                                    >
                                        {/* fileName || */}
                                        {'Upload Image (min 5)'}
                                    </label>
                                </div>
                            </div>
                            {renderDocumentPreview(geoTaggingFiles)}

                            <h6 className="text-lg font-bold">11. Self Declaration (mandatory)</h6>
                            <CheckBox
                                label="I have visited the working place personally."
                                checked={selfDeclaration1}
                                onChange={setSelfDeclaration1}
                            />
                            <Checkbox
                                label="Work has been executed as per the sanctioned estimate."
                                checked={selfDeclaration2}
                                onChange={setSelfDeclaration2}
                            />
                            <Checkbox
                                label="The quality of the work done is satisfactory."
                                checked={selfDeclaration3}
                                onChange={setSelfDeclaration3}
                            />
                            <Checkbox
                                label="Material used for the construction are tested in front of me and is found satisfactory."
                                checked={selfDeclaration4}
                                onChange={setSelfDeclaration4}
                            />

                            <h6 className="text-lg font-bold">12. Upload Material Testing Report</h6>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: 10,
                            }}>
                                <h6 className={`text-sm font-bold ml-8`}>Do you have document ? - No </h6>
                                <Switch
                                    value={mtrDocFlag}
                                    onChange={() => setMtrDocFlag(prev => !prev)}
                                />
                                <h6 className={`text-sm font-bold ml-8`}>Yes </h6>
                            </div>

                            {mtrDocFlag ? (
                                <>
                                    <div className="mt-2 flex items-center space-x-2 pb-2">
                                        <div className="relative flex items-center px-3 py-1 rounded-full border border-gray-300 bg-white">
                                            <input
                                                type="file"
                                                id="file-input"
                                                onChange={(e) => handleFilePick(e, setTestingReports)}
                                                className="absolute inset-0 opacity-0 cursor-pointer"
                                                multiple
                                            />
                                            <label
                                                htmlFor="file-input"
                                                className="flex items-center justify-center w-full h-10 px-4  text-black cursor-pointer"
                                            >
                                                {/* fileName || */}
                                                {'Upload Material Testing Report'}
                                            </label>
                                        </div>
                                    </div>
                                    {renderDocumentPreview(testingReports)}
                                    {/* <Text className="text-red-300 ml-5 mb-1">*max upload size 10MB</Text>
                                    {renderDocumentPreview(testingReports)} */}
                                </>
                            ) : (

                                <div className="mt-2 flex items-center space-x-2 pb-2">
                                    <div className="flex items-center p-3 rounded-full border border-gray-300">
                                        <input
                                            type="text"
                                            value={mtrDocComment}
                                            onChange={(e) => { setMtrDocComment(e.target.value) }}
                                            placeholder="Add comments about material testing report"
                                            className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                            style={{
                                                width: '200px',
                                                fontSize: '16px',
                                                borderRadius: '9999px',
                                                boxShadow: 'none',
                                                backgroundColor: '#ffffff',
                                                padding: '0 1rem',
                                            }}
                                        />
                                    </div>
                                </div>
                            )}

                            <div>
                                {!loading ? <button
                                    className="my-6 p-3 w-full rounded-full bg-primary"
                                    onClick={handleSubmit}
                                >
                                    <span className="text-center text-lg font-bold text-white">Submit</span>
                                </button> : <button
                                    className="my-6 p-3 w-full rounded-full bg-primary"
                                >
                                    <span className="text-center text-lg font-bold text-white">Loading...</span>
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <BottomBar />
            </div>
        </ScreenWrapper>
    )
}

export default AddProjectScreen