import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import LoginScreen from '../pages/LoginScreen';
import HomeScreen from '../pages/HomeScreen';
import ViewProjectScreen from '../pages/ViewProjectScreen';
import AddProjectScreen from '../pages/AddProjectScreen';
import ViewImage from '../pages/ViewImage';
import ProfileScreen from '../pages/ProfileScreen';
import EditProjectScreen from '../pages/EditProjectScreen';
import AddMasterProjectScreen from '../pages/AddMasterProjectScreen';

const AppRoutes = () => {
    // global state
    const [state] = useContext(AuthContext);
    // auth condition true false
    const authenticatedUser = state?.user;

    return (
        <Router>
            <Routes>
                {authenticatedUser ? (
                    <>
                        <Route path="/home" element={<HomeScreen />} />
                        {/* <Route path="/add-project" element={<AddProjectScreen />} /> */}
                        <Route path="*" element={
                            <>
                                <Navigate to="/home" />
                            </>
                        } />
                        <Route path='/view-project' element={<ViewProjectScreen />} />
                        <Route path='/add-project' element={<AddProjectScreen />} />
                        <Route path='/edit-project' element={<EditProjectScreen />} />
                        <Route path='/profile' element={<ProfileScreen />} />
                        <Route path='/view-document' element={<ViewImage />} />
                        <Route path='/master-project' element={<AddMasterProjectScreen />} />
                    </>
                ) : (
                    <>
                        <Route path="/login" element={<LoginScreen />} />
                        {/* Redirect to login if no match */}
                        <Route path="*" element={<Navigate to="/login" />} />
                    </>
                )}
            </Routes>
        </Router>
    );
};

export default AppRoutes;
