import React, { useContext, useEffect, useState } from 'react';
import { addMasterProject, getMasterProject } from '../request/request';
import BottomBar from '../components/BottomBar';
import { AuthContext } from '../context/authContext';
import ScreenWrapper from '../components/ScreenWrapper';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';

export default function AddMasterProjectScreen() {
    const [projectName, setProjectName] = useState('');
    const [projectData, setProjectData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const [state] = useContext(AuthContext);
    const currentUser = state.user;
    const navigate = useNavigate();

    const fetchProjectList = async () => {
        try {
            const projectDataResponse = await getMasterProject();
            if (projectDataResponse.res_code == 200) {
                setProjectData(projectDataResponse.data);
            } else {
                console.error('Failed to fetch projects:', projectDataResponse.message); // Log any error message
            }
        } catch (error) {
            console.error('Error fetching project list:', error);
        }
    };


    useEffect(() => {
        if (currentUser) {
            fetchProjectList();
        }
    }, [currentUser]);

    const refreshProject = async () => {
        await fetchProjectList();
    };

    const handleSubmit = async () => {
        try {
            const addTaskResponse = await addMasterProject(projectName);
            if (addTaskResponse.res_code == 200) {
                navigate("Home");
            } else {
                alert(addTaskResponse.message);
            }
        } catch (error) {
            alert('Project add failed', error.message);
        }
    };

    return (
        <ScreenWrapper>
            <div className="flex-1">
                <Header />
                <div className="p-4" style={{marginBottom:'60px'}}>
                    <div className="p-4 bg-white rounded-xl mb-4 shadow-md">
                        <span className="text-xl text-center font-bold text-green-400">Project Master</span>

                        <div className="mt-2">
                            <span className="text-lg font-bold text-black">Add Master Project</span>

                            <div className="mt-2 flex items-center space-x-2 pb-2">
                                <div className="flex items-center p-3 rounded-full border border-gray-300">
                                    <input
                                        type="text"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                        placeholder="Enter project name"
                                        className="appearance-none bg-transparent border-none outline-none text-gray-700"
                                        style={{
                                            width: '200px',
                                            fontSize: '16px',
                                            borderRadius: '9999px',
                                            boxShadow: 'none',
                                            backgroundColor: '#ffffff',
                                            padding: '0 1rem',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            className="my-6 mx-6 p-3 rounded-full bg-green-400"
                            onClick={handleSubmit}
                        >
                            <span className="text-center text-lg font-bold text-white">Submit</span>
                        </button>
                    </div>
                    <div className="flex-row justify-between items-center my-2">
                        <span className="text-xl font-bold text-black">Master Project</span>

                        {/* <TouchableOpacity
                            className="flex-row p-2 px-3 border border-gray-200 rounded-full shadow-sm bg-green-500"
                            onPress={() => setModalVisible(true)}
                        >
                            <Text className="font-bold text-sm text-white"> New</Text>
                        </TouchableOpacity> */}
                    </div>
                    <div>
                        {projectData.length == 0 ? <h5>Empty</h5> :
                            projectData.map(item => (
                                <div
                                    className="bg-white p-3 rounded-2xl mb-3 shadow-sm"
                                // onPress={() => navigation.navigate('divProject', { project: item })}
                                >
                                    <div>
                                        <div className="flex-row justify-between items-center">
                                            <span
                                                className="text-green-400 font-bold"
                                                style={{ width: 200 }}
                                            // numberOfLines={1}
                                            // ellipsizeMode="tail"
                                            >
                                                {item.master_project_name}
                                            </span>
                                            <div
                                                className="p-1 px-3 rounded-full shadow-sm"
                                                style={{ backgroundColor: '#BFDBFE' }}
                                            >
                                                <span className="text-white font-bold text-xs">master-project-{item.master_pid}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

            </div>
            <BottomBar />
        </ScreenWrapper>
    );
}
